
.ant-btn:hover {
    color: white !important;
    background-color: #3282FB !important;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
}

.ant-input-outlined{
    border-color: #A8A8A8;
    border-radius: 5px;
}
.ant-input-outlined:hover{
    border-color: #A8A8A8;
}
.ant-input-outlined:focus{
    border-color: #A8A8A8;
    box-shadow: none
}
.ant-input-outlined:focus-within{
    border-color: #A8A8A8;
    box-shadow: none
}
.ant-form-item .ant-form-item-label{
    padding:0px
}
.ant-form-item .ant-form-item-label >label{
    color: black;
    font-size: 15px;
}
@media screen and (max-width: 750px) {
    .ant-form-item .ant-form-item-label > label {
        font-size: 10px; /* Adjust the font size for smaller screens */
    }
}
.ant-input{
    padding: 10px 10px;
}
.ant-input-affix-wrapper{
    padding: 10px 10px;
}